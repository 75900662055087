@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
* {
    font-family: "Montserrat", sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

button {
    background: none;
    border: none;
}

.text-center {
    text-align: center;
}

.responsive-nav {
    transform: none !important;
}

.nav-close-btn {
    display: none;
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: white;
    font-size: 1.8rem;
}


@media only screen and (max-width: 720px) {
    nav .nav-close-btn {
        display: block;
        position: absolute;
        top: 2rem;
        right: 2rem;
    }
}
