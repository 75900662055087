.banner-image {
    width: 100%;
    height: auto;
    filter: brightness(50%);
    position: relative;
}

.banner-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 70%;
    width: 100%;
    margin-top: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner-text {
    width: 70%;
    color: white;
    margin: 10em;
}

.banner-text > h1 {
    font-size: 73px;
    color: white;
    font-weight: bold;
}

.banner-text > p {
    color: white;
    font-size: 16px;
}

.banner-text > button > a {
    color: white;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    text-decoration: none;
}

.primary-btn {
    background-color: #7177A8;
    margin: 5px;
    border-radius: 7px 7px 7px 7px;
    padding: 19px 45px 19px 45px;
}

.primary-btn:hover {
    background-color: #192673;
    cursor: pointer;
    color: white;
}

.secondary-btn {
    background-color: #192673;
    margin: 5px;
    border-radius: 7px 7px 7px 7px;
    padding: 19px 45px 19px 45px;
}

.secondary-btn:hover {
    background-color: #7177A8;
    cursor: pointer;
    color: white;
}

@media screen and (max-width: 1040px) {
    .banner-text {
        margin: 1em;
    }
}

@media screen and (max-width: 768px) {
    .banner-text > h1 {
        font-size: 49px;
    }
}
