.carousel-caption {
    position: relative !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
}

.carousel-caption > h3 {
    color: #192673;
    font-size: 17px;
    font-weight: bold;
}

.carousel-caption > p {
    font-size: 17px;
    font-weight: bold;
}

.carousel-item > p{
    font-size: 16px;
    text-align: center;
}