footer {
    background-color: #192673;
    height: auto;
    width: 100%;
    padding: 20px;
    color: #fff;
}

.footer-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footer-content > p {
    color: white;
    font-size: 13px;
}

.footer-content > b {
    color: white;
}

@media screen and (max-width: 500px) {
    .footer-content {
        flex-direction: column;
    }
}
