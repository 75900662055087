.header {
    display: flex;
    justify-items: center;
    align-items: center;
    margin: 5px 14em;
    color: #192673;
}

.header-title {
    font-size: 1em;
    padding: 0 10px;
}

.header-actions {
    margin-left: auto;
    display: flex;
}

.header-button {
    background: none;
    border: none;
    padding: 5px;
    font-weight: bold;
    color: #192673;
    text-decoration: none;
}

.header-button:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 0.5rem;
}


@media screen and (max-width: 1024px) {
    .header {
        margin: 1em;
    }
    .header-actions {
        flex-direction: column;
    }
}

@media screen and (max-width: 720px) {
    .header {
        flex-direction: column;
        margin: 5px;
    }

    .header-title {
        padding: 10px;
    }

    .header-actions {
        flex-direction: row;
        margin-left: 0;
    }
}
