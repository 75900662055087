hr {
    margin-top: 30px;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 1px solid #7177a8;
    width: 100%;
}

p {
    color: #7A7A7A;
    font-size: 21px;
    font-weight: normal;
    line-height: 2em;
}

.main-content {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}

.sub-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 14em;
}

.flex-row {
    flex-direction: row;
}

.accordion-content {
    justify-content: space-evenly;
    align-items: flex-start;
}

.sub-content p {
    text-align: center;
}

.paragraph-text {
    padding: 10px 20px;
}

.paragraph-text > p {
    font-size: 16px;
}

.heading-title {
    font-size: 34px;
    font-weight: bold;
    margin: 30px;
    color: #192673;
}

.content-btn {
    color: white;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 7px 7px 7px 7px;
    padding: 19px 45px 19px 45px;
    border: none;
    margin: 30px;
    background-color: #7177a8;
    text-decoration: none;
}

.content-btn:hover {
    background-color: #192673;
    color: white; /* fixes <a> hover color */
}

.tile-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.tile-container > div {
    border: 1px solid #e5e5e5;
    padding: 30px;
    flex: 45%;
    margin: 10px;
    text-align: center;
    background-color: #fcfcfc;
}

.tile-title {
    font-size: 21px;
    font-weight: bold;
    margin: 20px 0;
    color: #192673;
}

.tile-container-2 {
    display: flex;
    justify-content: space-evenly;
}

.content-image {
    border-radius: 30px;
    margin-right: 20px;
    box-shadow: -10px 0 24px 0;
    color: #7A7A7A;
}

.p-bolded {
    color: #545353;
    font-weight: bold;
}

.alternate-text {
    color: #7177A8;
    text-align: left !important;
}

.bold {
    font-weight: bold;
}

@media screen and (max-width: 924px) {
    .sub-content {
        margin: 0 2em;
    }

    .banner-text {
        margin: 5em;
    }
}

@media screen and (max-width: 1525px) {
    .tile-container-2 {
        flex-direction: column;
        align-items: center;
    }

    .content-image {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 768px) {
    .sub-content {
        margin: 0 1em;
    }
}

@media screen and (max-width: 720px) {
    .main-content {
        padding: 0;
    }
}
